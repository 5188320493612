<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2> Edit Screening Antibody</h2>
    </b-col>
    <b-col class="pl-16 pl-sm-32 py-24 py-sm-32">
    <b-row>
      <b-col cols="5">
        <div class="d-flex justify-content-between">
          <ul>
            <div class="mb-3">
              <label>Masukan Barcode</label>
              <b-form-textarea 
              v-model="screeningData.barcode"
              type="text"
              :rows="4"
              :state="v$.screeningData.barcode.$dirty ? !v$.screeningData.barcode.$error : null"
              />
              <b-form-invalid-feedback :state="v$.screeningData.barcode.$dirty ? !v$.screeningData.barcode.$error : null">
                {{ errorMessages.barcode }}
              </b-form-invalid-feedback>
            </div>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row  >
        <b-col cols="8">
          <b-row class="mb-3">
            <b-col cols="4" >
              <label>Antibody</label>
            </b-col>
            <b-col cols="3" >
              <b-form-select
                v-model="screeningData.antibody"
                :state="v$.screeningData.antibody.$dirty ? !v$.screeningData.antibody.$error : null"
                :options="options"
              />
              <b-form-invalid-feedback :state="v$.screeningData.antibody.$dirty ? !v$.screeningData.antibody.$error : null">
                {{ errorMessages.antibody }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    <b-col>
      <div class="d-flex justify-content-end mb-3">
          <b-button
              variant="primary-1 "
              type="submit"
              :loading="isBusy"
              @click="updateScreeningAntibody"
          >
            <b-spinner
              small 
              label="Small Spinner" 
              v-if="isBusy"
            />
            <span v-else>Simpan</span>
          </b-button>
        </div>
    </b-col>
  </b-col>
</b-row>
</template>

<script>
import { 
BButton, 
BCol, 
BRow, 
BFormInput,
BFormTextarea,
BFormRadio,
BFormGroup,
BFormSelect,
BFormInvalidFeedback,
BSpinner,
} from 'bootstrap-vue';
import {useVuelidate} from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import fieldMappings from './data/fieldMappings'

export default {
setup: () => ({ v$: useVuelidate() }),
name: 'edit-screeningAntibody',
 components: {
  BButton,
  BCol,
  BRow,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BFormGroup,
  BFormSelect,
  BFormInvalidFeedback,
  BSpinner,
 },
 data() {
  return {
    screeningData: {
      barcode:'',
      antibody: '',
    },
    errorMessages: {},
    options: [
      { value: '1', text: 'Positif' },
      { value: '0', text: 'Negatif' },
    ],
    
  }
},
validations() {
    return {
      screeningData: {
        barcode: { required, minLength: minLength(9) },
        antibody: {required},
      },
    }
  },
computed: {
    isBusy() {
      return this.$store.state.manageScreening.isBusy
    },
    screeningAntibody() {
    return this.$store.state.manageScreeningAntibody.screeningAntibody;
  }
},
methods: {
  async fetchData() {
    await this.$store.dispatch('manageScreeningAntibody/getDetailScreeningAntibody', this.$route.params.id)
    
    this.screeningData.barcode = this.screeningAntibody.barcode
    this.screeningData.antibody = this.screeningAntibody.antibody
  },
  async updateScreeningAntibody() {
    const formData = new FormData()
      formData.append('id', this.$route.params.id)
      formData.append('barcode', this.screeningData.barcode)
      formData.append('antibody', this.screeningData.antibody)
      await this.$store.dispatch('manageScreeningAntibody/updateScreeningAntibody', formData)
      .then(() => {
        this.$router.push({name: 'listscreeningantibodys'})
        this.$bvToast.toast('Data Screening berhasil diubah', {
            title: 'Success',
            variant: 'success',
            solid: true,
          });
       }).catch(err => {
        if (err.response && err.response.data && err.response.data.success === false && err.response.data.message) {
          const errorMessage = err.response.data.message
          Object.keys(this.screeningData).forEach(field => {
            if (this.v$.screeningData[field]) {
              this.v$.screeningData[field].$error = true
              this.v$.screeningData[field].$touch()
              this.$set(this.errorMessages, field, fieldMappings[field], errorMessage)
            }
          })
        }
      })
  },
},
created() {
  this.fetchData();
},
}
</script>
<style scoped>

</style>